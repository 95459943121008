.nav {
   color: #fff;
   grid-area: sidebar;
   position: relative;
}
.nav_notes_add {
   list-style: none;
   padding: 5px;
   margin-bottom: 5px;
   background-color: rgb(131, 131, 131);
   border-bottom: 2px outset rgba(131, 131, 131, 0.335);
}
.nav_notes_add:hover{
   background-color: rgb(64, 104, 131);
}
.nav_notes_add > a {
   text-decoration: none;
   color: white;
}
.sidebarpage__foldername {
   color: #b5b3b3;
   font-family: var(--custom-font);
   height: 50vh;
   margin: 0 0 0 auto;
   text-orientation: upright;
   width: 75px;
   writing-mode: vertical-lr;
   padding: 15px;
}
.sidebarpage_back{
   margin: 0 15px;
   width: 65%;
}