ul > li{
   list-style: none;
}
.note_add {
   list-style: none;
   padding: 5px;
   margin: 25px;
   background-color: rgb(131, 131, 131);
   border-bottom: 2px outset rgba(131, 131, 131, 0.335);
   width: 150px;
   border-radius: 5px;
   text-align: center;
   
}
.note_add:hover{
   background-color: rgba(30, 165, 243, 0.945)
}
.note_add > a {
   text-decoration: none;
   color: white;
   
}
.note_item{
   box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
   transition: 0.3s;
   width: 60%;
   padding: 10px;
   margin-bottom: 10px;
   background-color: #d8eef5b0;
   border-radius: 1%;
}