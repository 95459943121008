.App {
   display: grid;
   width: 100%;
   height: 550px;
   grid-template-areas: "head head"
                        "nav  main";
   grid-template-rows: 80px 1fr 30px;
   grid-template-columns: 150px 1fr;
}
.App > header {
   grid-area: head;
}
.App > nav {
   grid-area: nav;
   height: 100%;
   background-color: #c5c5c5cc;
   overflow: auto;
}
.App > main {
   grid-area: main;
}